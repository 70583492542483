@font-face {
  font-family: Dominican;
  src: url("../assets/fonts/dominica-webfont.woff2") format("woff2"), url("../assets/fonts/dominica-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

a {
  color: inherit;
  text-decoration: inherit;
  transition: .2s ease-in-out; }
  a:hover {
    text-decoration: inherit; }

button {
  border: 0;
  cursor: pointer;
  background: transparent;
  color: inherit;
  font-size: 16px;
  height: 44px;
  line-height: 35px;
  padding: 5px;
  font-family: Dominican;
  letter-spacing: 2px;
  text-transform: uppercase; }
  button svg {
    height: 34px;
    float: left;
    padding: 5px 8px 5px 5px; }

body {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  background: #010c1e;
  font-family: Georgia;
  color: #fff;
  font-size: 16px; }

.col-5 {
  width: 50%; }

.col-6 {
  width: 60%; }

.col-4 {
  width: 40%; }

.col-3 {
  width: 33.33%; }

.col-3, .col-4, .col-5, .col-6 {
  padding: 15px;
  float: left; }
  @media (max-width: 950px) {
    .col-3, .col-4, .col-5, .col-6 {
      width: 100%;
      float: none; } }

.clear {
  overflow: auto; }

.aspect-16-9 {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative; }
  .aspect-16-9 * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.container, .menu {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 90px; }
  @media (max-width: 950px) {
    .container, .menu {
      padding: 0 15px; } }

.row {
  margin: 0 -15px;
  overflow: auto;
  padding: 15px 0; }

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: Dominican;
  font-weight: 400; }

h1 {
  letter-spacing: 3px; }

h3 {
  font-size: 16px; }

.header {
  box-shadow: 0 0 5px #204687;
  position: relative;
  z-index: 10; }
  .header > .container, .header > .menu {
    overflow: auto; }
    @media (min-width: 701px) {
      .header > .container, .header > .menu {
        position: relative; } }

.navigation {
  background: #204687; }

.title {
  padding: 20px 0;
  float: left; }
  @media (max-width: 700px) {
    .title {
      font-size: 13px;
      padding: 10px 0; } }
  @media (max-width: 374px) {
    .title {
      font-size: 11px;
      padding: 10px 0; } }

.tagline {
  font-style: italic;
  font-family: Dominican; }

.social svg {
  fill: #cc8e20;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-left: 10px; }

.footer .social {
  clear: both;
  padding-bottom: 30px;
  margin-left: -10px; }

.header .social {
  position: absolute;
  bottom: 15px;
  right: 90px; }
  @media (max-width: 950px) {
    .header .social {
      right: 15px; } }
  @media (max-width: 700px) {
    .header .social {
      top: 86px; }
      .header .social svg {
        margin-left: 8px; } }
  @media (max-width: 374px) {
    .header .social {
      top: 77px; }
      .header .social a:last-child {
        display: none; } }

.menu > li > a {
  font-family: Dominican;
  letter-spacing: 2px;
  display: block;
  text-transform: uppercase; }

@media (max-width: 950px) {
  .menu .star-extra {
    display: none; } }

.menu-section {
  transition: .2s ease-in-out;
  float: left;
  padding: 0 15px; }
  @media (max-width: 700px) {
    .menu-section {
      width: 50%; } }
  @media (max-width: 550px) {
    .menu-section {
      width: 100%; }
      .menu-section + .menu-section {
        padding-top: 0 !important; } }
  .menu-section h6 {
    padding-bottom: .9em; }
  .menu-section a:hover {
    border-bottom: 1px dotted #fff; }

@media (max-width: 700px) {
  .menu-button {
    margin-left: 5px; }
  h3 {
    margin-top: 5px; }
  .menu {
    transition: .2s ease-in-out;
    list-style: none;
    padding: 0;
    max-height: 0;
    overflow: hidden; }
    .menu.is-open {
      max-height: 1000px; }
    .menu .star {
      display: none; }
    .menu > li {
      overflow: auto;
      position: relative; }
    .menu .hub::after {
      content: '+';
      font-family: Arial;
      position: absolute;
      right: 15px;
      top: 8px;
      font-size: 28px; }
    .menu .hub.is-open::after {
      content: '-';
      top: 5px; }
    .menu > li > a {
      border-top: 1px solid #183566;
      padding: 11px 15px;
      display: block; }
    .menu > li.is-open .menu-category {
      display: block;
      max-height: 1000px;
      padding-bottom: 10px; }
      .menu > li.is-open .menu-category .menu-section {
        padding: 15px; }
    .menu ul {
      list-style: none; }
  .menu-category {
    transition: .2s ease-in-out;
    background: #183566;
    overflow: hidden;
    max-height: 0; }
  .menu-listing {
    line-height: 1.5;
    font-size: 18px; }
    .menu-listing li {
      margin-bottom: 5px; }
  .menu-section h6 {
    padding-bottom: .4em; } }

@media (min-width: 701px) {
  .menu-button {
    display: none; }
  .menu {
    position: relative; }
    .menu svg {
      fill: #fff;
      margin: 5px 0 -3px;
      height: 20px; }
    .menu > li {
      display: inline-block; }
    .menu > li:first-child > a {
      padding-left: 0; }
    .menu > li > a {
      font-family: Dominican;
      padding: 6px 15px 4px;
      letter-spacing: 2px;
      display: block;
      text-transform: uppercase; }
    .menu > li > .menu-category {
      display: none; }
    .menu > li:hover a {
      background: #183566;
      position: relative;
      z-index: 10; }
    .menu > li:hover .menu-category {
      display: block; }
  .menu-category {
    position: absolute;
    background: #183566;
    padding: 20px 15px 15px;
    margin-left: -15px;
    border-bottom: 6px solid #cc8e20;
    line-height: 1.3;
    box-shadow: 0 0 5px #010c1e; }
  h3 {
    margin-bottom: 4px; }
  .menu-listing {
    list-style: none; }
    .menu-listing li {
      padding: 0 0 8px; } }

.callout {
  border-bottom: 6px solid #cc8e20;
  width: 100%;
  overflow: auto;
  position: relative; }
  .callout img {
    min-width: 100%;
    height: 580px;
    float: right;
    vertical-align: top;
    object-fit: cover; }
    @media (max-width: 950px) {
      .callout img {
        height: auto;
        float: none;
        width: 100%; } }

.event-page .callout img,
.info-page .callout img {
  width: 100%;
  max-width: 1600px;
  height: auto;
  min-width: 0;
  float: none;
  display: block;
  margin: 0 auto; }

.learn-more {
  display: inline-block;
  padding-top: .5em;
  border-bottom: 1px dotted transparent;
  position: relative; }
  .learn-more:hover {
    border-bottom: 1px dotted #fff; }
  .learn-more::after {
    content: ' \3009';
    position: absolute;
    right: -20px;
    font-family: Arial;
    top: 7px; }

.content {
  background: #500103;
  padding: 15px;
  margin: 0 .15em;
  box-shadow: 0 -5px 5px #500103;
  position: relative; }
  @media (min-width: 951px) {
    .content {
      top: 50%;
      margin-top: -140px;
      position: absolute;
      width: 350px;
      background: rgba(80, 1, 3, 0.85);
      box-shadow: 0 0 5px 5px rgba(80, 1, 3, 0.85); } }
  @media (max-width: 950px) {
    .content {
      margin: 0 -15px; } }
  .content h2 {
    line-height: 110%;
    font-variant: small-caps;
    text-transform: none;
    margin-bottom: 6px;
    letter-spacing: 1px; }
  .content p {
    line-height: 1.2; }
  .content .date {
    text-align: center; }
  .content .stars {
    text-align: center;
    margin: 5px 0 0;
    fill: #fff; }
    .content .stars svg {
      display: inline-block;
      height: 20px; }

.upcoming-events {
  padding-bottom: 15px; }
  @media (min-width: 951px) {
    .upcoming-events {
      float: left;
      width: 40%; } }

.date {
  display: block;
  font-style: italic;
  font-size: .9em;
  letter-spacing: 1px; }

.event {
  margin: 15px; }
  .event a:hover {
    border-bottom: 1px dotted #fff; }
  .event h4 {
    margin-bottom: .1em; }
  .event p {
    line-height: 1.3; }
  .event .date {
    padding-top: 6px; }

.events-heading {
  padding: 15px 15px 0; }
  .events-heading svg {
    fill: #fff;
    margin-top: 8px;
    height: 20px; }

.blurb .blurb-heading {
  margin: 0 0 8px; }

.blurb svg {
  margin-bottom: 6px;
  height: 20px; }

.blurb p, .blurb ul, .blurb ol {
  line-height: 1.4;
  margin: .25em 0 15px; }

.blurb ul, .blurb ol {
  padding-left: 1.5em; }

.blurb h1, .blurb h2, .blurb h3, .blurb h4, .blurb h5, .blurb h6 {
  margin: .5em 0 0; }

.blurb a {
  border-bottom: 1px dotted #fff; }

.blurb > p:first-of-type:first-letter {
  float: left;
  font-family: Dominican;
  font-size: 40px;
  line-height: 40px;
  padding: 3px 4px 0 3px; }

.blurb hr {
  height: 6px;
  background: #cc8e20;
  width: 100%;
  border: 0;
  margin: 1em 0; }

.blurb img {
  margin: .25em 1em 1em 0;
  float: left;
  width: 55%; }
  @media (max-width: 500px) {
    .blurb img {
      width: 100%;
      margin-right: 0; } }

.blurb blockquote {
  font-size: 1.5em;
  background: #500103;
  box-shadow: 0 0 5px 5px #500103;
  margin: 1em .15em;
  padding: 1px 15px; }
  @media (max-width: 700px) {
    .blurb blockquote {
      margin-left: -15px;
      margin-right: -15px; } }

.column-info .image, .column-info .video {
  padding: 15px 0;
  width: 100%;
  clear: both; }
  @media (max-width: 700px) {
    .column-info .image, .column-info .video {
      padding: 15px 0 0; } }
  .column-info .image:first-child, .column-info .video:first-child {
    padding-top: 0; }

.column-info img {
  width: 100%; }

.info-box {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
  border-left: 6px solid #cc8e20; }
  .info-box a {
    border-bottom: 1px dotted #fff; }

.links {
  padding: 15px;
  margin: 30px .15em 15px;
  background: #500103;
  box-shadow: 0 0 5px 5px #500103; }
  @media (max-width: 700px) {
    .links {
      margin: 30px -15px 0; } }
  .links a {
    display: block;
    font-family: Dominican;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 8px 0;
    border-bottom: 1px solid #370102; }
    @media (max-width: 700px) {
      .links a {
        font-size: 15px; } }
    @media (max-width: 374px) {
      .links a {
        font-size: 13px; } }
    .links a::after {
      content: '\3009';
      float: right;
      margin-right: -8px; }
    .links a:first-child {
      border-top: 1px solid #370102; }

.footer {
  padding: 30px 0 15px;
  background: #204687;
  overflow: auto;
  border-top: 6px solid #cc8e20; }
  .footer .container, .footer .menu {
    overflow: auto; }
  .footer small {
    padding-top: 45px;
    display: block; }
  .footer h6 {
    padding-top: .7em; }
  .footer li {
    padding-top: .4em; }
  .footer ul {
    list-style: none;
    padding-bottom: 1em; }
  .footer ul a, .footer h6 a {
    border-bottom: 1px dotted #fff; }
  .footer img {
    float: right;
    width: 280px; }
  @media (max-width: 1170px) {
    .footer img {
      display: none; } }
  .footer .social {
    float: left; }

.footer-callout {
  float: right; }
  .footer-callout span {
    position: relative;
    float: left;
    margin: 3px 12px 0 30px; }
  .footer-callout a {
    font-family: Dominican;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px dotted transparent; }
    .footer-callout a:hover {
      border-bottom: 1px dotted #fff; }
  .footer-callout span::after {
    content: '\3009';
    position: absolute;
    top: 1px;
    right: -12px; }
  @media (max-width: 1130px) {
    .footer-callout {
      margin-top: -10px;
      padding-bottom: 30px;
      float: left;
      clear: both; }
      .footer-callout span:first-child {
        margin-left: 0; } }
  @media (max-width: 760px) {
    .footer-callout {
      padding-bottom: 15px; }
      .footer-callout span {
        display: block;
        float: none;
        margin-left: 0;
        margin-bottom: 10px; } }

.footer-menu {
  float: left;
  padding-right: 30px; }
  .footer-menu:last-of-type {
    padding-right: 0; }
  @media (max-width: 760px) {
    .footer-menu {
      width: 100%;
      padding-right: 0; } }

.gallery {
  overflow: auto;
  padding-bottom: 15px;
  display: flex;
  flex-flow: row wrap; }
  .gallery::after {
    content: '';
    flex-grow: 1000000000; }

.gallery-item {
  padding: 15px;
  float: left;
  max-width: 100%; }
  .gallery-item img {
    width: 100%;
    cursor: pointer; }

#popup {
  display: none;
  background: rgba(1, 12, 30, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  #popup.is-open {
    display: flex; }

#popup-close {
  font-size: 30px;
  font-weight: bold;
  float: right; }

#popup-content img {
  padding: 15px;
  background: #010c1e;
  max-width: 90vw;
  max-height: 90vh; }

form {
  clear: both; }
  form img {
    display: none !important; }

.container input[type=submit], .menu input[type=submit], .container button, .menu button, .container .button, .menu .button {
  border-radius: 0;
  -webkit-appearance: none;
  margin: 0 10px 20px 0;
  border: 0;
  padding: 10px 15px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #cc8e20;
  color: #fff;
  font-family: Dominican;
  cursor: pointer;
  display: inline-block; }
  .container input[type=submit]:hover, .menu input[type=submit]:hover, .container button:hover, .menu button:hover, .container .button:hover, .menu .button:hover {
    background: #a9751a; }

.payment {
  padding: 15px;
  border: 6px solid #cc8e20;
  margin: 30px 0 15px;
  overflow: auto; }
  .payment form {
    padding-top: 5px; }

.payment-button {
  float: left;
  width: auto; }
